@import './assets/fonts/fonts';

:root {
  --primaryFontFamily: 'Univers';
  --secondaryFontFamily: 'Corporate A Pro';
  --white: #fff;
  --black-100: #232323;
  --black-200: #363636;
  --black-300: #3b3b3b;
  --black-400: #4a4f54;
  --balck-500: #555454;
  --red: #ce0e2d;
  --red-200: #b50a26;
  --gray-100: #979797;
  --gray-200: #868686;
  --gray-300: #9c9fa2;
  --gray-400: #b5b7b9;
  --gray-500: #d9e2eb;
  --gray-600: #e1e2e3;
  --gray-700: #edf2f7;
  --shadow-100: 0 0 14px rgba(0, 0, 0, 0.0654775);
  --shadow-200: 0 4px 20px 2px rgba(17, 48, 109, 0.1);
}

html,
body {
  height: 100%;
  background-color: var(--white);
  color: var(--white);
}

body {
  font-family: var(--primaryFontFamily);
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--white);
}

p {
  margin: 0;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.background-overlay {
  position: relative;

  & > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.fallback-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
