.privateFooter {
  display: flex;
  justify-content: space-between;
  padding: 48px 88px;
  font-family: var(--secondaryFontFamily);
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;

  @media (min-width: 1115px) {
    padding: 48px 88px 0;
  }

  &.lightFooter {
    background-color: var(--white);
    color: var(--black-400);
  }

  &.darkFooter {
    background-color: #222;
    color: var(--white);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 60px 15px 0;
  }

  .infoBlock {
    max-width: 988px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      margin-bottom: 53px;
    }

    .contactInfo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 14px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: baseline;
      }

      .logoFooter {
        max-width: 212px;
        max-height: 90px;
        margin-right: 100px;

        @media (max-width: 768px) {
          max-width: 100%;
          margin-bottom: 70px;
        }
      }

      .infoSecond {
        max-width: 340px;
        width: 100%;

        @media all and (max-width: 1140px) and (min-width: 768px) {
          margin: 0 20px;
        }

        .contact {
          a {
            color: inherit;
          }
        }

        .title {
          padding-bottom: 20px;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;

          @media (max-width: 768px) {
            padding-bottom: 16px;
          }
        }
      }

      .infoThird {
        max-width: 252px;
        width: 100%;

        @media (max-width: 768px) {
          margin: 32px 0;
        }
      }
    }

    .privacyInfo {
      display: flex;
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    .privacy-link {
      font-size: 13px;
      line-height: 18px;
      text-decoration: underline;
      margin-bottom: 56px;
      color: inherit;
    }
  }

  .iconBlock {
    display: flex;
    align-items: flex-end;
  }

  .iconBlockCustom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 768px) {
      width: 160px;
    }

    @media (min-width: 769px) and (max-width: 1114px) {
      position: relative;
      top: 48px;
    }

    .icon {
      max-height: 140px;
      width: 100%;
      object-fit: contain;
    }
  }
}
