.header {
  height: 175px;

  .logoLink {
    @media (min-width: 1111px) {
      margin-right: 20px;
    }

    .logo {
      max-width: 212px;
      max-height: 90px;
    }
  }

  .topHeader {
    background-color: var(--red);
    display: flex;
    justify-content: center;

    .topHeaderContainer {
      max-width: 1440px;
      width: 100%;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 87px;
      font-size: 15px;
      line-height: 15px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        padding: 0 20px;
      }

      .leftBlock {
        position: relative;
        display: flex;
        align-items: center;

        @media (max-width: 1110px) {
          display: none;
        }

        .miniLogo {
          margin-right: 20px;
        }
      }

      .rightBlock {
        position: relative;
        display: flex;
        align-items: center;

        @media (max-width: 1110px) {
          width: 100%;
          justify-content: space-between;
        }

        .callIcon {
          @media (min-width: 1111px) {
            display: none;
          }
        }

        .langBlock {
          position: relative;
          z-index: 10;
        }

        .langButton {
          position: relative;
          transition: 0.3s all ease;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 107px;
          height: 65px;
          margin-right: 10px;
          padding: 0 20px;

          @media (max-width: 1110px) {
            margin-right: 0;
          }

          &.selectedClr {
            background-color: var(--red-200);
          }
        }

        .selectLang {
          position: absolute;
          bottom: -226px;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s all ease;
          display: flex;
          flex-direction: column;
          background-color: var(--white);
          width: 107px;
          padding: 8px 0;
          box-shadow: 0 0 11px rgba(0, 0, 0, 0.228939);
          color: var(--black-100);

          &.openSelectLang {
            visibility: visible;
            opacity: 1;
          }

          .langBlock {
            display: flex;
            align-items: center;
            padding: 5px 15px 5px 18px;
            color: var(--black-100);

            &:hover {
              opacity: 0.6;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .iconFlag {
          margin-right: 10px;
        }

        .lang {
          line-height: 11px;
        }

        .verticalLine {
          border: 1px solid var(--white);
          height: 30px;
          margin-right: 25px;

          @media (max-width: 1110px) {
            display: none;
          }
        }

        .logoutButton {
          padding: 10px 0;

          @media (max-width: 1110px) {
            display: none;
          }
        }

        .burgerButton {
          padding: 0;

          @media (min-width: 1111px) {
            display: none;
          }
        }
      }

      .item {
        display: flex;
        align-items: center;
        margin-right: 30px;

        .iconFlag {
          margin-right: 10px;
        }
      }
    }
  }

  .menuHeader {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: var(--black-100);
    position: relative;
    z-index: 6;
    background-color: var(--white);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    &.dark {
      background-color: var(--black-200);

      .dropdown {
        border-bottom: 1px solid transparent;
      }

      a {
        color: var(--white);
      }
    }

    .backgroundImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
      transition: all 0.5s ease;
    }

    &.backImg {
      .backgroundImage {
        opacity: 1;
      }
    }

    .headerMenuContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1440px;
      width: 100%;
      height: 110px;
      padding: 0 87px;
      text-transform: uppercase;
      z-index: 1;

      @media (max-width: 1200px) {
        width: auto;
        padding: 0;
      }

      .navBlock {
        display: flex;
        align-items: center;

        @media (max-width: 1110px) {
          display: none;
        }

        .links {
          margin-right: 10px;

          a {
            color: var(--black-400);
          }

          &.dark {
            a {
              color: var(--white);
            }
          }

          .dropdownButton {
            color: var(--black-100);

            &.dark {
              color: var(--white);
            }

            &.colorRed {
              color: var(--red);
            }
          }

          .link {
            padding: 0 20px;

            &.active {
              color: var(--red);
            }
          }

          .arrowDown {
            margin-left: 10px;
          }
        }

        .verticalBlackLine {
          border: 1px solid var(--black-100);
          height: 30px;
          margin-right: 25px;

          &.dark {
            border-color: var(--white);
          }
        }

        .buttonClose {
          margin-right: 20px;
        }
      }

      .menuList {
        background-color: var(--white);
        width: 100%;
      }
    }

    .dropdown {
      width: 100%;
      height: 0;
      display: flex;
      justify-content: center;
      background-color: transparent;
      position: relative;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s all ease;
      border-bottom: 1px solid var(--gray-600);

      //@media (max-width: 1100px) {
      //  display: none;
      //}

      &.open {
        height: 280px;
        visibility: visible;
        opacity: 1;

        @media (max-width: 1110px) {
          height: auto;
          padding-bottom: 50px;
        }
      }

      .dropdownContent {
        display: flex;
        justify-content: space-between;
        max-width: 1440px;
        width: 100%;
        padding: 0 87px;
        font-size: 18px;
        text-transform: uppercase;

        @media (max-width: 1110px) {
          display: none;
        }

        .leftBlock {
          width: 20%;
          display: flex;
          align-items: center;
          background-position: center;
          background-repeat: no-repeat;
        }

        .rightBlock {
          width: 75%;

          .dropdownMenu {
            display: flex;
            justify-content: space-between;
            margin-bottom: 35px;
            padding-top: 14px;
            padding-bottom: 14px;
            border-bottom: 2px solid var(--gray-300);

            .tabs {
              display: flex;

              .tab_item {
                margin-right: 40px;
                color: var(--black-500);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;

                &.dark {
                  color: var(--white);
                }

                &.active {
                  color: var(--red);
                }

                &.darkActive {
                  color: var(--red);
                }

                &:hover {
                  color: var(--red);
                }
              }
            }

            .viewAll {
              display: flex;
              align-items: center;
              color: var(--gray-400);

              &.dark {
                color: var(--white);
              }

              .viewAllText {
                margin-right: 5px;
              }
            }
          }

          .tab {
            a {
              color: var(--black-500);

              &:hover {
                color: var(--red);
              }
            }

            &.dark {
              a {
                color: var(--white);

                &:hover {
                  color: var(--red);
                }
              }
            }

            &.multiColumns {
              -moz-column-count: 5;
              -moz-column-gap: 20px;
              -webkit-column-count: 5;
              -webkit-column-gap: 20px;
              column-count: 5;
              column-gap: 20px;
              list-style-position: inside;
            }

            .productItem {
              display: block;
              margin-bottom: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            }
          }
        }
      }

      .drawer {
        width: 100%;

        @media (min-width: 1111px) {
          display: none;
        }

        .containerList {
          max-width: 768px;
          width: 100%;
          margin: 0 auto;
          border-top: 1px solid var(--gray-100);

          .navLink {
            display: block;
            width: 100%;
            padding: 25px 45px;
            border-bottom: 1px solid var(--gray-100);
            text-transform: uppercase;
          }

          a {
            color: var(--black-200);
          }

          &.dark {
            a {
              color: var(--white);
            }
          }

          &.openListCategory {
            .navLink {
              display: none;
            }
          }

          .listBtn {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 25px 45px;
            border-bottom: 1px solid var(--gray-100);
            text-align: left;
            text-transform: uppercase;
            color: var(--black-200);

            &.dark {
              color: var(--white);
            }

            &.leftBtn {
              justify-content: left;
            }

            .arrowLeft {
              display: none;
              margin-right: 12px;

              &.iconBtnLeft {
                display: block;
                transform: rotate(0.5turn);
              }
            }

            .arrowRight {
              display: none;

              &.iconBtnRight {
                display: block;
              }
            }
          }

          .categoryBtn {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 25px 45px;
            border-bottom: 1px solid var(--gray-100);

            &.open {
              border-bottom: none;
              padding-bottom: 16px;
            }

            &.white {
              color: var(--white);
            }

            .arrowBottom {
              transform: rotate(0.25turn);

              &.arrowTop {
                transform: rotate(0.75turn);
              }
            }
          }

          .productList {
            &.white {
              color: var(--white);
            }

            .productItem {
              display: block;
              padding: 10px 45px 5px;
            }
          }
        }
      }
    }
  }
}
