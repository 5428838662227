.publicFooter {
  background-color: var(--black-100);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 48px 88px;
  font-family: var(--secondaryFontFamily);

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    padding: 60px 15px 0;
  }

  .publicInfoBlock {
    max-width: 988px;

    @media (max-width: 768px) {
      margin-bottom: 55px;
    }

    .title {
      padding-bottom: 20px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }

    .subTitle {
      padding-bottom: 5px;
    }

    .phoneEmail {
      padding-bottom: 23px;

      a {
        color: inherit;
      }
    }

    .text {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    .privacy-link {
      font-size: 13px;
      line-height: 18px;
      text-decoration: underline;
      margin-bottom: 56px;
      color: inherit;
    }
  }

  .companyInfo {
    margin-bottom: 15px;
  }

  hr {
    width: 1px;
    margin: 0 auto;
    border-color: var(--gray-100);
    height: 100%;

    @media (max-width: 768px) {
      display: none;
    }
  }
}
