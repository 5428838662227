.searchContainer {
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 1110px) {
    width: 100%;
    padding: 25px 41px;
    border-bottom: 1px solid var(--gray-100);
  }

  input {
    border: 0;
    background: none;
    position: relative;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 20px;
    transition: right 1s ease-in-out;
    font-family: var(--primaryFontFamily);

    &:focus {
      outline: none;
    }

    &.active {
      visibility: visible;
      opacity: 1;
      width: 100%;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.12px;
      color: var(--gray-300);

      @media (min-width: 1111px) {
        width: 600px;
      }
    }

    &.dark {
      color: var(--white);
    }
  }

  ::placeholder {
    font-family: var(--primaryFontFamily);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.12px;
    text-transform: uppercase;
    color: var(--gray-300);
  }

  &.dark {
    ::placeholder {
      color: var(--white);
    }
  }

  .openButton {
    padding: 0;
  }

  .search {
    @media (max-width: 1110px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .searchLabel {
      color: var(--black-200);
      text-transform: uppercase;

      @media (min-width: 1111px) {
        display: none;
      }

      &.dark {
        color: var(--white);
      }
    }
  }

  .errMsg {
    position: absolute;
    // right: 30px;
    // top: 4px;
    font-size: 14px;
    color: var(--red);

    right: 80px;
    top: 50%;
    transform: translateY(-50%);
  }
}
