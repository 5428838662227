// Univers

@font-face {
  font-family: 'Univers';
  src: url('./Univers/UniversLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Univers';
  src: url('./Univers/UniversCnRg.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Univers';
  src: url('./Univers/UniversCnBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Univers';
  src: url('./Univers/UniversRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Univers';
  src: url('./Univers/UniversBold.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

// Corporate A Pro

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-DemiItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Corporate A Pro';
  src: url('./CorporateAPro/CorporateAPro-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
